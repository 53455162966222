




















import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  inheritAttrs: false
})
export default class BaseSelect extends Vue {
  @Prop({ default: null }) value: string
}
