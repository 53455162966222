















import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  inheritAttrs: false
})
export default class BaseRadio extends Vue {
  @Prop({ default: null }) value: string
  @Prop({ default: '' }) label: string

  isActive = false
}
