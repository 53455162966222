



















import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  inheritAttrs: false
})
export default class BaseCheckbox extends Vue {
  @Prop({ default: false }) value: boolean
  @Prop({ default: '' }) label: string
}
