import Area from '@/model/area'
import TargetRoundMatchService from '@/services/target-round-match'

export default class BurmaRoadService extends TargetRoundMatchService {
  protected numberOfRounds = 9

  protected roundTargets: Array<Area> = [
    new Area(15),
    new Area(16),
    new Area(null, 2),
    new Area(17),
    new Area(18),
    new Area(null, 3),
    new Area(19),
    new Area(20),
    new Area(25)
  ]

  protected initPlayers (): void {
    this.match.players.forEach(player => {
      player.score.value = 40
    })
  }

  protected hit (): void {
    if (this.match.hit.match(this.match.roundTarget)) {
      this.match.activePlayer.score.add(this.match.hit.getTotal())
    }

    super.hit()
  }

  public endPlayerRound (): void {
    if (!this.match.hits.some(hit => hit.match(this.match.roundTarget))) {
      this.resetPlayerScore(this.match.activePlayer, Math.ceil(
        this.match.activePlayer.score.value / 2
      ))
    }

    super.endPlayerRound()
  }
}
