import { Observable, of, Subject, Subscription, timer } from 'rxjs'
import { switchMap } from 'rxjs/operators'

import Hit from '@/model/hit'
import Match from '@/model/match'
import Player from '@/model/player'

export default class MatchService {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public options: Record<string, any>
  public match: Match

  public deactivePlayerSubject: Subject<boolean> = new Subject()
  public deactivePlayerSubscription: Subscription
  private deactivePlayerObservable: Observable<number> = this.deactivePlayerSubject.pipe(switchMap(value => {
    return value ? timer(2000) : of(null)
  }))

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public resume (options: Record<string, any>, match: Match): void {
    this.options = options

    this.match = match

    if (this.match.waitingForNextPlayer) {
      this.startPlayerRound()
    } else if (this.match.waitingForNextRound) {
      this.nextRound()
    }
  }

  public start (match: Match): void {
    this.match = match

    this.match.round = 1
    this.initPlayers()

    this.init()
  }

  public shoot (hit: Hit): void {
    this.match.hits.push(hit)

    this.match.throws--

    if (this.match.hit !== null) {
      this.match.tempScore += this.match.hit.getTotal()
    }

    this.hit()
  }

  protected init (): void {
    this.startRound()
  }

  protected initPlayers (): void {
    this.match.players.forEach(player => {
      player.score.value = 0
    })
  }

  protected startRound (): void {
    this.startPlayerRound()
  }

  protected startPlayerRound (): void {
    if (this.match.activePlayer != null) {
      this.match.activePlayer.endingRound = false
    }

    this.match.nextPlayer()

    this.match.activePlayer.active = true
    this.match.activePlayer.score.init()

    this.match.hits = []
    this.match.throws = 3
    this.match.tempScore = 0

    this.match.message = this.match.activePlayer.name
  }

  protected hit (): void {
    if (this.match.throws === 0) {
      this.endPlayerRound()
    }
  }

  public endPlayerRound (): void {
    this.match.activePlayer.endingRound = true
    this.match.activePlayer.active = false
    this.match.activePlayer.nextMember()

    if (this.match.activePlayer === this.match.lastPlayer) {
      this.endRound()
    } else {
      this.deactivePlayerSubscription = this.deactivePlayerObservable.subscribe(() => {
        this.deactivePlayerSubscription.unsubscribe()
        this.startPlayerRound()
      })
      this.deactivePlayerSubject.next(true)
    }
  }

  protected endRound (): void {
    this.deactivePlayerSubscription = this.deactivePlayerObservable.subscribe(() => {
      this.deactivePlayerSubscription.unsubscribe()
      this.nextRound()
    })
    this.deactivePlayerSubject.next(true)
  }

  private nextRound (): void {
    this.match.round++
    this.startRound()
  }

  protected setWinner (player: Player): void {
    player.rank = this.match.winners.length + 1

    this.continue()
  }

  protected continue (): void {
    if (this.match.inGamePlayers.length > 0) {
      if (this.match.inGamePlayers.length === 1) {
        this.match.inGamePlayers[0].rank = this.match.winners.length + 1
      } else {
        this.endPlayerRound()
      }
    }
  }

  protected resetPlayerScore (player, score): void {
    player.score.reset = true
    player.score.value = score

    setTimeout(() => {
      player.score.reset = false
    })
  }

  protected orderWinnersByHighestScore (): void {
    const winners = this.match.players.slice().sort((playerA, playerB) => playerA.score.value - playerB.score.value)

    winners.forEach((player, index) => {
      if (index === 0) {
        player.rank = this.match.winners.length + 1
      } else {
        const previousPlayer = winners[index - 1]

        if (player.score.value === previousPlayer.score.value) {
          player.rank = previousPlayer.rank
        } else {
          player.rank = index + 1
        }
      }
    })
  }
}
