import { Target as BaseTarget } from 'scoreboard'

import Area from '@/model/area'
import serializable from '@/decorators/serializable'

@serializable
export default class Target extends Area implements BaseTarget {
  public drawing = false
  public closed = false
}
