import { Player as BasePlayer } from 'scoreboard'

import Score from '@/model/score'
import serializable from '@/decorators/serializable'

@serializable
export default class Player implements BasePlayer {
  public members: Array<string> = []
  public score: Score = new Score()
  public hits: [number, number, number, number, number, number, number] = [null, null, null, null, null, null, null]
  public active = false
  public endingRound = false
  public rank: number = null
  public hasZapped = false

  constructor (members: Array<string> = []) {
    this.members = members
  }

  get name (): string {
    if (this.members.length > 0) {
      return this.members[0]
    }

    return ''
  }

  public nextMember (): void {
    if (this.members.length > 1) {
      this.members.push(this.members.shift())
    }
  }
}
