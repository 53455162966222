import Player from './player'

export default class NewMatch {
  public players: Array<Player>
  public gameCode: string
  public options: { [id: string]: string}

  constructor (players: Array<Player>, gameCode: string, options: { [id: string]: string}) {
    this.players = players
    this.gameCode = gameCode
    this.options = options
  }
}
