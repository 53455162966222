








import { Component, Prop, Vue } from 'vue-property-decorator'

import BaseRadio from './BaseRadio.vue'

@Component({
  inheritAttrs: false
})
export default class BaseRadioGroup extends Vue {
  @Prop({ default: null }) value: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any

  radios: { [code: string]: BaseRadio} = {}
  _value: string = null

  mounted () {
    for (const radio of this.$children as Array<BaseRadio>) {
      radio.$on('click', () => {
        this.select(radio.value)
      })
      this.radios[radio.value] = radio
    }

    if (this.value != null) {
      this._value = this.value
      this.select(this.value)
    }
  }

  select (value) {
    if (this._value != null) {
      const currentRadio = this.radios[this._value]
      if (currentRadio != null) {
        currentRadio.isActive = false
      }
    }

    if (this._value === value) {
      this._value = null
      this.$emit('input', null)
    } else {
      const selectedRadio = this.radios[value]
      if (selectedRadio != null) {
        selectedRadio.isActive = true

        this._value = value
        this.$emit('input', this._value)
      }
    }
  }
}
