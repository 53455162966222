import { Match as BaseMatch } from 'scoreboard'

import Area from '@/model/area'
import Hit from '@/model/hit'
import Player from '@/model/player'
import Target from '@/model/target'
import serializable from '@/decorators/serializable'

@serializable
export default class Match implements BaseMatch {
  public players: Array<Player> = []

  public throws: number = null
  public player: number = null
  public round: number = null

  public tempScore: number = null
  public roundTarget: Area = null

  public hits: Array<Hit> = []
  public targets: [Target, Target, Target, Target, Target, Target, Target] = [
    new Target(),
    new Target(),
    new Target(),
    new Target(),
    new Target(),
    new Target(),
    new Target()
  ]

  public message = ''

  public get waitingForNextPlayer (): boolean {
    return (this.activePlayer != null) && this.activePlayer.endingRound
  }

  public get waitingForNextRound (): boolean {
    return this.waitingForNextPlayer && (this.activePlayer === this.lastPlayer)
  }

  public get started (): boolean {
    return this.round != null
  }

  public get finished (): boolean {
    return this.players.every(player => player.rank != null)
  }

  public get activePlayer (): Player {
    return this.players[this.player]
  }

  public get lastPlayer (): Player {
    return this.inGamePlayers[this.inGamePlayers.length - 1]
  }

  public get inGamePlayers (): Array<Player> {
    return this.players.filter(player => player.rank == null)
  }

  public get otherPlayers (): Array<Player> {
    if (this.player == null) {
      return this.inGamePlayers
    }

    return [
      ...this.inGamePlayers.slice(this.player + 1),
      ...this.inGamePlayers.slice(0, this.player)
    ]
  }

  public get winners (): Array<Player> {
    return this.players.filter(player => {
      return player.rank != null
    })
  }

  public get hit (): Hit {
    if (this.hits[3 - this.throws - 1] != null) {
      return this.hits[3 - this.throws - 1]
    }

    return null
  }

  public get randomizeTargets (): Array<Target> {
    return this.targets.filter(target => (target.drawing === true))
  }

  public nextPlayer () {
    this.player = this.inGamePlayers.indexOf(this.otherPlayers[0])
  }

  public clone (match: Match): void {
    this.players = match.players

    this.throws = match.throws
    this.player = match.player
    this.round = match.round

    this.tempScore = match.tempScore
    this.roundTarget = match.roundTarget

    this.hits = match.hits
    this.targets = match.targets

    this.message = match.message
  }
}
