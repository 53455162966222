import MatchService from '@/services/match'

export default class FixRoundMatchService extends MatchService {
  protected numberOfRounds: number

  protected endRound (): void {
    if (this.numberOfRounds === this.match.round) {
      this.match.activePlayer.endingRound = false

      this.orderWinnersByHighestScore()
    } else {
      super.endRound()
    }
  }
}
