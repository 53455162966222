import FixRoundMatchService from '@/services/fixed-rounds-match'

export default class HighScoreService extends FixRoundMatchService {
  protected numberOfRounds = 10

  protected hit (): void {
    this.match.activePlayer.score.add(this.match.hit.getTotal())

    super.hit()
  }
}
