



























































import { Component, Vue, Watch } from 'vue-property-decorator'

import { cloneDeep } from 'lodash'
import Scoreboard from 'scoreboard'

import DartBoard from '@/components/DartBoard.vue'
import NewMatchForm from '@/components/NewMatchForm/index.vue'
import NewMatch from '@/components/NewMatchForm/model/new-match'
import Dialog from '@/components/ui/Dialog.vue'
import Splash from '@/components/Splash.vue'
import Hit from '@/model/hit'
import Match from '@/model/match'
import Player from '@/model/player'
import MatchService from '@/services/match'
import MatchFactory from '@/services/match-factory'
import { parse, stringify } from '@/utils/json'

@Component({
  components: {
    DartBoard,
    Dialog,
    NewMatchForm,
    Scoreboard,
    Splash
  }
})
export default class App extends Vue {
  splash = process.env.VUE_APP_BUILD_TARGET === 'web'
  newMatchDialog = false;

  match: Match = new Match();
  gameCode: string;
  history: Array<Match> = [];
  matchService: MatchService = null;

  @Watch('match', { deep: true })
  onMatchChange () {
    if (this.match.started) {
      window.localStorage.setItem(
        'match-backup',
        stringify({
          options: this.matchService.options,
          match: this.matchService.match,
          gameCode: this.gameCode,
          history: this.history
        })
      )
    } else if (this.match.finished) {
      window.localStorage.removeItem('match-backup')
    }
  }

  created () {
    if (window.localStorage.getItem('match-backup') != null) {
      try {
        const { gameCode, options, match, history } = parse(
          window.localStorage.getItem('match-backup')
        )

        this.gameCode = gameCode
        this.history = history

        this.match = match
        this.matchService = MatchFactory.getService(gameCode)
        this.matchService.resume(options, match)

        this.$forceUpdate()
      } catch (error) {
        window.localStorage.removeItem('match-backup')
      }
    }
  }

  mounted () {
    if (this.splash) {
      setTimeout(() => {
        this.splash = false
      }, 2000)
    }
  }

  errorCaptured () {
    this.match = new Match()
    this.matchService = null

    window.localStorage.removeItem('match-backup')
  }

  openNewMatchDialog () {
    this.newMatchDialog = true
  }

  closeNewMatchDialog () {
    this.newMatchDialog = false
  }

  start (newMatch: NewMatch) {
    this.closeNewMatchDialog()

    this.matchService = MatchFactory.getService(newMatch.gameCode)
    if (this.matchService != null) {
      this.gameCode = newMatch.gameCode
      this.history = []

      this.matchService.options = newMatch.options

      this.match = new Match()
      this.match.players = newMatch.players.map(
        (newPlayer) =>
          new Player(newPlayer.members.map((member) => member.name))
      )

      this.matchService.start(this.match)
    }
  }

  nextPlayer (): void {
    if (this.match.activePlayer.endingRound) {
      this.matchService.endPlayerRound()
    } else {
      this.matchService.endPlayerRound()
    }
  }

  cancel (): void {
    if (
      this.matchService.deactivePlayerSubject.observers != null &&
      this.matchService.deactivePlayerSubject.observers.length
    ) {
      this.matchService.deactivePlayerSubscription.unsubscribe()
    }
    this.match = this.history.pop()
    this.matchService.match = this.match

    this.$forceUpdate()
  }

  shoot (hit: Hit): void {
    if (
      this.matchService.deactivePlayerSubject.observers != null &&
      this.matchService.deactivePlayerSubject.observers.length
    ) {
      this.matchService.deactivePlayerSubject.next(false)
    } else {
      this.history.push(cloneDeep(this.match))

      this.matchService.shoot(hit)
    }
  }
}
