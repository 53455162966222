import BurmaRoad from '@/services/burma-road'
import Cricket from '@/services/cricket'
import HighScore from '@/services/high-score'
import MatchService from '@/services/match'
import Shanghai from '@/services/shanghai'
import X01 from '@/services/x01'
import Zap from '@/services/zap'

export default class MatchFactory {
  public static getService (gameCode: string): MatchService {
    let matchService: MatchService
    switch (gameCode) {
      case 'BURMA_ROAD':
        matchService = new BurmaRoad()
        break
      case 'CRICKET':
        matchService = new Cricket()
        break
      case 'HIGH_SCORE':
        matchService = new HighScore()
        break
      case 'SHANGAI':
        matchService = new Shanghai()
        break
      case 'X01':
        matchService = new X01()
        break
      case 'zap':
        matchService = new Zap()
        break
    }

    return matchService
  }
}
