import Area from '@/model/area'
import TargetRoundMatchService from '@/services/target-round-match'

export default class ShanghaiService extends TargetRoundMatchService {
  protected numberOfRounds = 7

  protected roundTargets: Array<Area> = [
    new Area(1),
    new Area(2),
    new Area(3),
    new Area(4),
    new Area(5),
    new Area(6),
    new Area(7)
  ]

  private static shanghaiTargets: Array<Area> = [
    new Area(null, 1),
    new Area(null, 2),
    new Area(null, 3)
  ]

  protected hit (): void {
    if (this.match.hit.match(this.match.roundTarget)) {
      this.match.activePlayer.score.add(this.match.hit.getTotal())
    }

    super.hit()
  }

  public endPlayerRound (): void {
    if ((this.match.hits.length === 3) && this.hasShanghai()) {
      this.setWinner(this.match.activePlayer)
    } else {
      super.endPlayerRound()
    }
  }

  private hasShanghai () {
    return ShanghaiService.shanghaiTargets.every(target => this.match.hits.some(hit => hit.match(target)))
  }
}
