



























import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  inheritAttrs: false
})
export default class BaseInput extends Vue {
  @Prop({ default: undefined }) placeholder: string
  @Prop({ default: 'text' }) type: string
  @Prop({ default: null }) value: string

  $refs: {
    input: HTMLElement;
  }

  focused = false

  focus (): void {
    const input = this.$refs.input
    input.focus()
  }

  onBlur (event: Event): void {
    this.focused = false
    this.$emit('blur', event)
  }

  onFocus (event: Event): void {
    this.focused = true
    this.$emit('focus', event)
  }
}
