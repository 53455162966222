



























































































































































import { Component, Vue } from 'vue-property-decorator'

import BaseButton from '@/components/ui/BaseButton.vue'
import BaseCheckbox from '@/components/ui/BaseCheckbox.vue'
import BaseFormField from '@/components/ui/BaseFormField.vue'
import BaseInput from '@/components/ui/BaseInput.vue'
import BaseRadio from '@/components/ui/BaseRadioGroup/BaseRadio.vue'
import BaseRadioGroup from '@/components/ui/BaseRadioGroup/index.vue'
import BaseSelect from '@/components/ui/BaseSelect.vue'
import { games } from './data/games'
import Game from './model/game'
import Player from './model/player'
import Member from './model/member'
import NewMatch from './model/new-match'

@Component({
  components: {
    BaseButton,
    BaseCheckbox,
    BaseInput,
    BaseFormField,
    BaseRadio,
    BaseRadioGroup,
    BaseSelect
  }
})
export default class NewMatchPanel extends Vue {
  players: Array<Player> = [new Player('Player 1'), new Player('Player 2')]
  gameCode: string = null
  options: { [code: string]: string} = {}
  games: Array<Game> = games

  get game (): Game {
    if (this.gameCode != null) {
      return this.games.find(game => game.code === this.gameCode)
    }
    return null
  }

  getNewMemberName () {
    return `Player ${this.players.reduce((accumulator, player) => accumulator + player.members.length, 0) + 1}`
  }

  addPlayer () {
    this.players.push(new Player(this.getNewMemberName()))
  }

  addMember (player: Player) {
    player.members.push(new Member(this.getNewMemberName()))
  }

  removeMember (playerIndex: number, memberIndex: number) {
    const player = this.players[playerIndex]
    if (player.members.length === 1) {
      this.players.splice(playerIndex, 1)
    } else {
      player.members.splice(memberIndex, 1)
    }
  }

  resetOptions () {
    this.options = {}

    if (this.game.options != null) {
      this.game.options.forEach(option => {
        if (option.defaultValue != null) {
          this.options[option.code] = option.defaultValue
        }
      })
    }
  }

  submit () {
    if (
      this.players.every(player => player.members.every(member => ((member.name != null) && (member.name !== '')))) &&
      (this.gameCode != null) &&
      ((this.game.options == null) || this.game.options.every(option => !option.required || (this.options[option.code] != null)))
    ) {
      this.$emit('start', new NewMatch(this.players, this.gameCode, this.options))
    }
  }
}
