









import { Component, Vue } from 'vue-property-decorator'

@Component({
  inheritAttrs: false
})
export default class Splash extends Vue {}
