import Area from '@/model/area'
import FixRoundMatchService from '@/services/fixed-rounds-match'

export default class TargetRoundMatchService extends FixRoundMatchService {
  protected roundTargets: Array<Area>

  protected startRound (): void {
    this.match.roundTarget = this.roundTargets[this.match.round - 1]

    super.startRound()
  }
}
