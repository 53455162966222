import { Area as BaseArea } from 'scoreboard'

import serializable from '@/decorators/serializable'

@serializable
export default class Area implements BaseArea {
  public section: number
  public coefficient: number

  constructor (section: number = null, coefficient: number = null) {
    this.section = section
    this.coefficient = coefficient
  }

  public getTotal (): number {
    return this.section * this.coefficient
  }
}
