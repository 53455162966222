










import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  inheritAttrs: false
})
export default class BaseButton extends Vue {
  @Prop({ default: false }) small: boolean
  @Prop({ default: false }) icon: boolean
  @Prop({ default: 'button' }) type: string
}
