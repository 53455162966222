






















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator'

import Hit from '@/model/hit'

@Component({
  inheritAttrs: false
})
export default class DartBoard extends Vue {
  @Prop({ default: false }) disabled
  @Prop() hits: Hit

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  hit (section: number, coefficient: number, event: any): void {
    if (!this.disabled) {
      event.stopPropagation()
      const targetElement = event.target
      const parentElement = targetElement.parentElement

      this.$emit('shoot',
        new Hit(
          section,
          coefficient,
          event.offsetX / parentElement.clientWidth,
          event.offsetY / parentElement.clientHeight
        )
      )
    }
  }
}
